import fetchAPI from "./fetchAPI"

export default async function fetchLatestArticles(context, page) {
  const { fetchLatestArticles } = await fetchAPI(
    `query($context: String!, $page: Int!) {
        fetchLatestArticles(context: $context, page: $page) {
          articles
        }
      }`,
    {
      variables: { context, page }
    }
  )

  return fetchLatestArticles
}
