import { useState } from "react"
import classNames from "classnames"
import { usePropsObject } from "@/lib/contexts/appContext"
import fetchLatestArticles from "@/lib/api/fetchLatestArticles"
import Block from "@/components/wrappers/block"
import Article from "@/components/common/article/article"
import Button from "@/common/button/button"
import BlockHeader from "@/common/partials/block-header/block-header"
import styles from "./latest-articles.module.css"

export default function LatestArticles({ block }) {
  const { heading, text, context, bgColor, articles, maxPage, button } =
    block.attrs
  const { theme } = usePropsObject()
  const [allArticles, setArticles] = useState(articles)

  if (articles.length === 0) {
    return null
  }

  return (
    <Block
      block={block}
      className={classNames(styles.wrapper, "bg-color-" + bgColor, {
        "text-white": theme?.bgColor == "black"
      })}
      innerWrapper={true}
      width="bleed">
      <BlockHeader heading={heading} text={text} />
      <div className={styles.blockGrid}>
        {allArticles.map((article, index) => (
          <div key={"article-" + index} className="flex">
            <Article
              className={styles.article}
              item={article}
              showImage={true}
            />
          </div>
        ))}
      </div>
      <LoadButton
        {...{ button, maxPage, context, theme, allArticles, setArticles }}
      />
    </Block>
  )
}

const LoadButton = ({
  button,
  maxPage,
  context,
  theme,
  allArticles,
  setArticles
}) => {
  const [isFetching, setIsFetching] = useState(false)
  const [nextPage, setPage] = useState(2)

  if (nextPage > maxPage) {
    return <div className={styles.noMoreArticles}>Inga fler artiklar</div>
  }

  const fetchArticlesOnClick = async () => {
    if (isFetching) {
      return
    }

    setIsFetching(true)

    const articlesObj = await fetchLatestArticles(context, nextPage)
    if (articlesObj.articles.length > 0) {
      const prettyArticles = articlesObj.articles.map((article) => {
        return article
      })
      setArticles([...allArticles, ...prettyArticles])
      setPage(nextPage + 1)
    }
    setIsFetching(false)
  }

  return (
    <Button
      className={styles.loadMoreButton}
      theme={theme?.accentColor ?? "light-lime"}
      tagName={button.url ? "a" : ""}
      href={button.url}
      onClick={fetchArticlesOnClick}
      loading={isFetching}
      disabled={isFetching}>
      {isFetching && <span>Laddar...</span>}
      {!isFetching && button.label}
    </Button>
  )
}
