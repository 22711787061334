import HeadingLabel from "@/common/partials/headingLabel/headingLabel"
import classNames from "classnames"
import Image from "next/image"
import Link from "next/link"
import { useState } from "react"
import styles from "./article.module.css"

function Article({ item, className, showImage = false, showTag = true }) {
  const { meta, tag, image } = item
  const [hovered, setHovered] = useState(false)
  const dateLine = [
    !item.meta.dateline?.showModified
      ? item.meta.dateline.created
      : item.meta.dateline.modified,
    item.meta.dateline.readTime > 0
      ? item.meta.dateline.readTime + " min att läsa"
      : false
  ]

  return (
    <div className={classNames(styles.article, hovered ? styles.hovered : "")}>
      {showImage && image.url && (
        <Link href={item.url} prefetch={false}>
          <a title={item.title} className={styles.link}>
            <figure
              className={styles.figure}
              onMouseEnter={() => {
                setHovered(true)
              }}
              onMouseLeave={() => {
                setHovered(false)
              }}>
              <Image
                className={styles.image}
                src={image.url}
                sizes="(max-width: 768px) 100vw, 25vw"
                alt={image.alt}
                objectPosition="center"
                objectFit="cover"
                layout="fill"
              />
            </figure>
          </a>
        </Link>
      )}
      <div className={classNames(className, hovered ? "hovered" : "")}>
        {tag && showTag && (
          <>
            {tag.url ? (
              <Link href={tag.url} prefetch={false}>
                <a title={tag.label}>
                  <HeadingLabel className="mb-2.5 block">
                    {tag.label}
                  </HeadingLabel>
                </a>
              </Link>
            ) : (
              <HeadingLabel>{tag.label}</HeadingLabel>
            )}
          </>
        )}

        {item.url && (
          <>
            <Link href={item.url} prefetch={false}>
              <a
                title={item.title}
                className="block"
                onMouseEnter={() => {
                  setHovered(true)
                }}
                onMouseLeave={() => {
                  setHovered(false)
                }}>
                <h5 className={styles.headline}>{item.title}</h5>
              </a>
            </Link>
            <span className={styles.meta}>
              {meta.byline && <span className="block">Av: {meta.byline}</span>}
              {dateLine.filter(Boolean).join(" • ")}
            </span>
          </>
        )}
      </div>
    </div>
  )
}

export default Article
